<template>
  <div class="container" :key="key">
    <div class="row">
      <div class="col-12 col-md-6">
        <h1 class="titlePage titlePage_textLeft">{{ title }}</h1>
      </div>
      <div class="toolbar col-12 col-md-12 text_right">
        <router-link
                :to="{name: 'customer-details'}"
                tag="p"
                class="toolbar__item"
        >
          <a href="" class="text toolbar__link">
            <svg-icon name="plus"/>
            Create new
          </a>
        </router-link>
        <!--<p class="toolbar__item" @click="openSearch"><svg-icon name="search"/>Search</p>-->
        <p class="toolbar__item" :class="{disabled: !enabledFilter}" @click="openFilter">
          <svg-icon name="filter"/>
          Filter</p>
      </div>
    </div>

    <p v-if="!items.length" class="text text_italic text_green">
      You have not created any orders yet. Please click the button 'Create new' to create an order.
    </p>

    <order-item @onRefresh="refreshOrders"></order-item>

    <transition name="fade" mode="out-in">
      <filter-orders v-if="openModal"></filter-orders>
    </transition>

  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue'

  import {mapGetters, mapState, mapActions} from 'vuex';

  export default {
    name: "OrdersList",
      components: {
      OrderItem :  defineAsyncComponent(() => import('./OrderItem')),
      FilterOrders : defineAsyncComponent(() => import('../modals/FilterOrders')),
    },
    watch: {
      $route: {
        immediate: true,
        handler(to, from) {
            const pageType = this.$route.query.orderType
            this.pageType = pageType
            this.$store.commit('orderItems/setSortBy', pageType)
            localStorage.setItem('ordersListType', pageType);

            if(pageType === 'quote-and-estimate') {
              this.$store.commit('filterOrders/setFilterTypeToQuotesEstimatesAndDrafts')
            }
            else if(pageType === 'orders') {
              this.$store.commit('filterOrders/setFilterTypeToOrders')
            }else {
              this.$store.commit('filterOrders/setFilterTypeToAll')
            }

            this.refreshOrders()
            this.$store.dispatch('auth/updateAbilities')
            this.key ++
        }
      },
    },
    data() {
      return {
        key : 0,
        pageType: 'all-orders'
      }
    },
    computed: {
      ...mapGetters({
        statuses: 'filterOrders/statuses',
          //searchOrders: 'filterOrders/searchOrders',
        filterType: 'orderItems/sortBy'
      }),
      ...mapGetters({items: 'orderItems/items'}),
      ...mapState('filterOrders', ['openModal']),
      enabledFilter() {
        return this.statuses.length > 1
      },
      title() {
        if(this.filterType === 'quotesEstimatesAndDrafts' || this.filterType === 'quote-and-estimate') return 'QUOTES AND ESTIMATES'
        if(this.filterType === 'orders') return 'MY ORDERS'
        return 'ALL ORDERS'
      }
    },

    methods: {
      ...mapActions('filterOrders', ['getListStatuses']),
      openFilter() {
        if (!this.enabledFilter) {
          return false;
        }
        this.$store.commit('filterOrders/setOpenModal', true);
      },
        openSearch(){
            this.$store.commit('filterOrders/searchOrders', true);
        },
      refreshOrders() {
        const pageType = this.$route.query.orderType
        let states = ['DRFT','EST','QOT', 'ORD']
        if(pageType === 'quote-and-estimate') {
          states = ['DRFT','EST','QOT']
        }
        else if(pageType === 'orders') {
          states = ['ORD']
        }
        this.$store.dispatch('orderItems/getOrders',{states });
      }
    },

    created() {
      // const orderType = this.$route.query.orderType
      // this.$store.commit('orderItems/setOrderType', orderType)
      this.getListStatuses()
    },
    beforeDestroy() {
      this.$store.commit('orderItems/setSortBy', undefined)
    }
  }
</script>

<style scoped lang="scss">
  .toolbar{
    padding-top: 15px;
  }
</style>
